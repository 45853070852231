import { Box, Stack, styled, Typography } from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import SalaryTransactionFilterDilog from "app/components/common/Dialogs/hr/payroll/SalaryTransactionFilterDilog";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
import TableActionButtons from "app/components/common/TableActionButtons";
import CommonTable1 from "app/components/Tables/CommonTable1";
import appRoutes from "app/config/routes";
import { getPartPaymentSalary } from "app/services/schoolService/hr.service";
import { FormatFullName, IndianCurrency } from "app/utils/helper";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SalaryTransactions = () => {
  const [state, setState] = useState({
    filterData: false,
  });
  const handleMutate = (payload) => {
    setState((prev) => ({
      ...prev,
      filterData: payload,
      fetch: !prev.fetch,
    }));
  };
  const {
    isLoading: getPartPaymentSalaryLoading,
    isFetching: getPartPaymentSalaryFetching,
    data: getPartPaymentSalaryData,
  } = useQuery({
    queryKey: ["getPartPaymentSalary", state.fetch],
    queryFn: () => {
      if (state.filterData) {
        return getPartPaymentSalary(state.filterData);
      } else {
        return getPartPaymentSalary();
      }
    },
    onSuccess: (succes) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);
  let header = [
    "Employee Name",
    "Amount",
    "Transaction Date",
    "Created On",
    "Created By",
  ];
  let exportArray = getPartPaymentSalaryData?.data?.list?.map((item) => {
    return {
      "Employee Name": item?.staffDocId?.firstName
        ? FormatFullName(
            item?.staffDocId?.firstName,
            item.staffDocId?.middleName,
            item?.staffDocId?.lastName
          )
        : "",
      Amount: item?.paidAmount ? IndianCurrency(item?.paidAmount) : "0",
      "Transaction Date": item?.transactionDate
        ? dayjs(item?.transactionDate).format("DD MMM, YYYY")
        : "--",
      "Created On": item?.createdOn
        ? dayjs(item?.createdOn).format("DD MMM, YYYY")
        : "--",
      "Created By": item?.createdBy?.personName ?? "--",
    };
  });
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        accessorKey: "srNo",
        Cell: ({ cell }) => {
          return (
            <>
              <Typography>
                {parseInt(cell?.row?.id) < 9
                  ? `0${parseInt(cell?.row?.id) + 1}`
                  : parseInt(cell?.row?.id) + 1}
              </Typography>
            </>
          );
        },
        maxSize: 30,
      },

      {
        header: "Employee name",
        accessorKey: "name",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <LinkText
              to={`/${appRoutes.staffProfile}/${original?.staffDocId?._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {original?.staffDocId?.firstName ?? ""}{" "}
              {original?.staffDocId?.middleName ?? ""}{" "}
              {original?.staffDocId?.lastName ?? ""} &#8599;
            </LinkText>
          );
        },
        size: 40,
      },

      {
        header: "Amount",
        accessorKey: "paidAmount",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>{IndianCurrency(original?.paidAmount)}</Typography>
          );
        },
        size: 30,
      },
      {
        header: "Transaction Date",
        accessorKey: "transactionDate",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {dayjs(original?.transactionDate).format("DD MMM, YYYY")}
            </Typography>
          );
        },
        size: 40,
      },
      {
        header: "Created On",
        accessorKey: "createdOn",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {dayjs(original?.createdOn).format("DD MMM, YYYY")}
            </Typography>
          );
        },
        size: 40,
      },
      {
        header: "Created By",
        accessorKey: "createdBy.personname",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.createdBy?.personName}</Typography>;
        },
        size: 40,
      },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <TableActionButtons showDelete />;
        },
        size: 20,
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Payroll | Salary Transaction</title>
      </Helmet>
      <CustomLoader
        show={getPartPaymentSalaryLoading || getPartPaymentSalaryFetching}
      />
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Salary Transaction"
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={20}
        justifyContent={"space-between"}
        mt={1}
      >
        <SalaryTransactionFilterDilog mutate={handleMutate} />

        <ExportButtonGroup
          handleClickExcel={() => setOpenCheckBox(true)}
          showPdf={false}
        />
      </Stack>
      <Stack>
        <FeeBox>
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Cash
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(
                  getPartPaymentSalaryData?.data?.summaryData?.t_CashMode
                )}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                NEFT
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(
                  getPartPaymentSalaryData?.data?.summaryData?.t_NEFTMode
                )}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Cheque
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(
                  getPartPaymentSalaryData?.data?.summaryData?.t_ChequeMode
                )}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                UPI
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(
                  getPartPaymentSalaryData?.data?.summaryData?.t_UpiMode
                )}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Card
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(
                  getPartPaymentSalaryData?.data?.summaryData?.t_CardMode
                )}
              </Typography>
            </Box>
          </Stack>
        </FeeBox>
      </Stack>

      <Stack py={1}>
        <CommonTable1
          columns={columns}
          data={getPartPaymentSalaryData?.data?.list || []}
          maxHeight="56vh"
        />
      </Stack>
    </>
  );
};

export default SalaryTransactions;
const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 5px;
  display: inline-block;
  margin-top: 10px;
  width: 40%;
`;
const LinkText = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: blue;
  }
`;
