import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let exam_url = `${SCHOOL_BASE}/exams`;

export const getExams = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getExams`, {
    params: params,
  });
};
export const deleteExam = (id) => {
  return schoolTokenAxios.delete(`${exam_url}/deleteExam/${id}`);
};
export const delete_CBSE_ReportCards = (id) => {
  return schoolTokenAxios.delete(`${exam_url}/delete_CBSE_ReportCards/${id}`);
};
export const rejectExam = (id) => {
  return schoolTokenAxios.put(`${exam_url}/rejectExam/${id}`);
};
export const approveExam = (id) => {
  return schoolTokenAxios.put(`${exam_url}/approveExam/${id}`);
};
export const getExamslist = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getExams`, {
    params: params,
  });
};
export const getStudentListForExamForm = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getStudentListForExamForm`, {
    params: params,
  });
};
export const getStudentListWithMarks = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getStudentListWithMarks`, {
    params: params,
  });
};
export const getAllApprovedExamList = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getAllApprovedExamList`, {
    params: params,
  });
};

export const createExam = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/createExam`, payload);
};

export const get_CBSE_ReportCard_Template = (params) => {
  return schoolTokenAxios.get(`${exam_url}/get_CBSE_ReportCard_Template`, {
    params: { ...params },
  });
};
export const get_Co_Scholastic_Description = (params) => {
  return schoolTokenAxios.get(`${exam_url}/get_Co_Scholastic_Description`, {
    params: { ...params },
  });
};
export const get_StudentList_With_Co_Scholastic_Description = (params) => {
  return schoolTokenAxios.get(
    `${exam_url}/get_StudentList_With_Co_Scholastic_Description`,
    {
      params: { ...params },
    }
  );
};

export const create_CBSE_ReportCard_TemplatePost = (payload) => {
  return schoolTokenAxios.post(
    `${exam_url}/create_CBSE_ReportCard_Template`,
    payload
  );
};

export const copy_CBSE_ReportCardTemplate = (payload) => {
  return schoolTokenAxios.post(
    `${exam_url}/copy_CBSE_ReportCardTemplate`,
    payload
  );
};
export const copyAdmitCard = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/copyAdmitCard`, payload);
};
export const delete_CBSE_ReportCardTemplate = (reportCardTemplateDocId) => {
  return schoolTokenAxios.delete(
    `${exam_url}/delete_CBSE_ReportCardTemplate/${reportCardTemplateDocId}`
  );
};

export const update_CBSE_ReportCard_Template = (payload) => {
  return schoolTokenAxios.put(
    `${exam_url}/update_CBSE_ReportCard_Template`,
    payload
  );
};

export const update_Co_ScholasticDetail = (payload) => {
  return schoolTokenAxios.put(
    `${exam_url}/update_Co_ScholasticDetail`,
    payload
  );
};
export const updateScholasticMark = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/updateScholasticMark`, payload);
};
export const bulkUpdateScholasticMark = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/bulkUpdateScholasticMark`, payload);
};
export const bulk_update_Co_ScholasticDetail = (payload) => {
  return schoolTokenAxios.put(
    `${exam_url}/bulk_update_Co_ScholasticDetail`,
    payload
  );
};

export const changeStatus_CBSE_ReportCardTemplate = (payload) => {
  return schoolTokenAxios.put(
    `${exam_url}/changeStatus_CBSE_ReportCardTemplate`,
    payload
  );
};
export const getAdmitCard = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getAdmitCard`, {
    params: { ...params },
  });
};

export const getSubjectDropdown = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getSubjectDropdown`, {
    params: { ...params },
  });
};
export const getAdmitCardCopy = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getAdmitCard`, {
    params: { ...params },
  });
};

export const createAdmitCard = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/createAdmitCard`, payload);
};

export const updateStatusOfAdmitCard = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/updateStatusOfAdmitCard`, payload);
};

export const getAdmitCardForSelectedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${exam_url}/getAdmitCardForSelectedStudent`,
    payload
  );
};

export const updateAdmitCard = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/updateAdmitCard`, payload);
};

export const getExamwiseReportCards = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getExamwiseReportCards`, {
    params: { ...params },
  });
};
export const getExamwiseCrossList = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getExamwiseCrossList`, {
    params: { ...params },
  });
};
export const rejectExamwiseReportCard = (id) => {
  return schoolTokenAxios.put(`${exam_url}/rejectExamwiseReportCard/${id}`);
};
export const approveExamwiseReportCard = (id) => {
  return schoolTokenAxios.put(`${exam_url}/approveExamwiseReportCard/${id}`);
};
export const deleteExamwiseReportCard = (id) => {
  return schoolTokenAxios.delete(`${exam_url}/deleteExamwiseReportCard/${id}`);
};

export const getScholasticColumnList = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getScholasticColumnList`, {
    params: { ...params },
  });
};
export const getCoScholasticColumnList = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getCoScholasticColumnList`, {
    params: { ...params },
  });
};
export const getStudentListForSubject = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getStudentListForSubject`, {
    params: { ...params },
  });
};

export const getSubjectDropdownForTermAndExam = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getSubjectDropdownForTermAndExam`, {
    params: { ...params },
  });
};
export const createReportCard = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/createReportCard`, payload);
};

export const fillScholasticDetail = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/fillScholasticDetail`, payload);
};
export const fillCoScholasticDetail = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/fillCoScholasticDetail`, payload);
};

export const approveReportCard = (reportCardTemplateDocId) => {
  return schoolTokenAxios.get(
    `${exam_url}/approveReportCard/${reportCardTemplateDocId}`
  );
};

export const rejectReportCard = (reportCardTemplateDocId) => {
  return schoolTokenAxios.get(
    `${exam_url}/rejectReportCard/${reportCardTemplateDocId}`
  );
};
export const deleteAdmitCard = (admitCardDocId) => {
  return schoolTokenAxios.delete(
    `${exam_url}/deleteAdmitCard/${admitCardDocId}`
  );
};
export const createCBSE_ReportCard = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/createCBSE_ReportCard`, payload);
};
export const updateAttendanceRecord = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/updateAttendanceRecord`, payload);
};
export const getCBSE_ReportCards = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getCBSE_ReportCards`, {
    params: { ...params },
  });
};
export const approveCBSE_ReportCard = (id) => {
  return schoolTokenAxios.put(`${exam_url}/approveCBSE_ReportCard/${id}`);
};

export const rejectCBSE_ReportCard = (id) => {
  return schoolTokenAxios.put(`${exam_url}/rejectCBSE_ReportCard/${id}`);
};
export const refreshRank = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/refreshRank`, payload);
};

export const getReportCardListByStudent = (params) => {
  return schoolTokenAxios.get(`${exam_url}/getReportCardListByStudent`, {
    params,
  });
};

//Advanced APIs
export const deleteCoScholasticRow = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/deleteCoScholasticRow`, payload);
};
export const deleteScholasticRow = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/deleteScholasticRow`, payload);
};
export const updateMaximumMarkForCell = (payload) => {
  return schoolTokenAxios.put(`${exam_url}/updateMaximumMarkForCell`, payload);
};
export const addScholasticRow = (payload) => {
  return schoolTokenAxios.post(`${exam_url}/addScholasticRow`, payload);
};
