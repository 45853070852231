import { Grid, Stack, TextField, Typography } from "@mui/material";
import { getAllRouteList } from "app/services/student-management";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import TextFieldLabel from "../common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useFormik } from "formik";
import RouteStopDropDown from "./RouteStopDropDown";
import {
  getAllCollectionHeadAndSubHead,
  getAllExpenseHeadAndSubHead,
} from "app/services/schoolService/ledgerNaccounts.service";
import CollectionHeadDropdown from "./CollectionHeadDropdown";
import ExpenseHeadDropdown from "./ExpenseHeadDropdown";

const ByExpenseHeadAndSubHead = ({ prevHeadList, setSelectedHead, filter }) => {
  const [headList, setHeadList] = useState([]);
  const formik = useFormik({
    initialValues: {
      postHeadList: prevHeadList,
    },
    onSubmit: (values) => {},
  });
  const { refetch: fetchRouteDetails, data: routeResponse } = useQuery({
    queryKey: ["getAllExpenseHeadAndSubHead"],
    queryFn: () => getAllExpenseHeadAndSubHead(),
    onSuccess: (res) => {
      let transformedList = res?.data?.list;
      if (filter && filter.head && filter.subHead) {
        // If filter is present, transform the data to match the filter structure
        const filteredList = transformedList.map((item) => {
          const body = {
            expenseHeadDocId: item?._id,
            expenseHeadName: item?.expenseHeadName,
            subHeadList: [],
            checked: false,
          };

          const updatedSubHeadList = item?.subHeadList?.map((subHeadItem) => {
            const isChecked =
              item?._id === filter.head && subHeadItem?._id === filter.subHead;

            return {
              expenseSubHeadName: subHeadItem?.expenseSubHeadName,
              expenseSubHeadDocId: subHeadItem?._id,
              display: `${item?.expenseHeadName}(${subHeadItem?.expenseSubHeadName})`,
              checked: isChecked,
            };
          });

          body.subHeadList = updatedSubHeadList;
          body.checked =
            updatedSubHeadList.length > 0 &&
            updatedSubHeadList.every((subHead) => subHead.checked === true);

          return body;
        });

        setHeadList(filteredList);
        // Create a new array to include both head details and the filtered sub-head list
        const postHeadList = filteredList
          .map((item) => {
            const filteredSubHeads = item.subHeadList.filter(
              (subHead) => subHead.checked
            );
            if (filteredSubHeads.length > 0) {
              return {
                expenseHeadDocId: item.expenseHeadDocId,
                expenseHeadName: item.expenseHeadName,
                subHeadList: filteredSubHeads,
                display: filteredSubHeads
                  ?.map((subHeadProp) => {
                    return `${item.expenseHeadName}(${subHeadProp.expenseSubHeadName})`;
                  })
                  .join(", "),
              };
            }
            return null;
          })
          .filter((item) => item !== null); // Remove null items where no sub-heads were checked

        // console.log("postHeadList:", postHeadList);

        // Set formik state with both head and filtered sub-head details
        formik.setValues({
          postHeadList,
        });
      } else if (formik.values.postHeadList.length > 0) {
        const transformedList = res?.data?.list.map((item) => {
          const body = {
            expenseHeadDocId: item?._id,
            expenseHeadName: item?.expenseHeadName,
            subHeadList: [],
            checked: false,
          };
          const updatedSubHeadName = item?.subHeadList?.map((head) => {
            const isChecked = formik?.values?.postHeadList.some(
              (postClass) =>
                postClass?.expenseHeadDocId === item?._id &&
                postClass?.checked === true &&
                postClass?.expenseSubHeadDocId === head?._id
            );
            return {
              expenseSubHeadName: head?.expenseSubHeadName,
              expenseSubHeadDocId: head?._id,
              display: `${item?.expenseHeadName}(${head?.expenseSubHeadName})`,
              checked: isChecked,
            };
          });

          body.subHeadList = updatedSubHeadName;
          body.checked =
            updatedSubHeadName.length > 0 &&
            updatedSubHeadName.every(
              (head) => head.checked && head.checked === true
            );

          return body;
        });
        setHeadList(transformedList);
      } else {
        // console.log(res?.data?.list);

        const arr = [];
        res?.data?.list?.forEach((item) => {
          const body = {
            expenseHeadDocId: item?._id,
            expenseHeadName: item?.expenseHeadName,
            subHeadList: [],
            checked: false,
          };
          item?.subHeadList?.forEach((stop) => {
            body?.subHeadList?.push({
              expenseSubHeadName: stop?.expenseSubHeadName,
              expenseSubHeadDocId: stop?._id,
              checked: false,
            });
          });
          arr.push(body);
        });
        setHeadList(arr);
      }
    },
  });

  useEffect(() => {
    setSelectedHead(formik.values.postHeadList);
  }, [formik.values.postHeadList]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextFieldLabel title={"Head Name"} />
          <CustomSelectBox
            size="small"
            multiple
            fullWidth
            MenuProps={MenuProps}
            value={formik.values.postHeadList}
            error={
              formik.touched.postHeadList && Boolean(formik.errors.postHeadList)
            }
            renderValue={() => {
              if (formik.values?.postHeadList?.length !== 0) {
                return (
                  <Stack direction="row" gap={0.5}>
                    <Typography
                      sx={{
                        width: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {formik.values.postHeadList
                        .map((cls) => cls?.display)
                        .join(", ")}
                    </Typography>
                  </Stack>
                );
              }
              return <Typography>Select</Typography>;
            }}
          >
            {headList?.map((item, ind) => (
              <ExpenseHeadDropdown
                item={item}
                index={ind}
                formikSetState={formik.setValues}
                formikState={formik.values}
                key={ind}
              />
            ))}
          </CustomSelectBox>
        </Grid>
      </Grid>
    </>
  );
};

export default ByExpenseHeadAndSubHead;
