import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios, authTokenAxios } from "../axios";

export const getInvitationCode = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.school.getInvitationCode);
};
export const sendReminderForFee = (params) => {
  return schoolTokenAxios.get(API_ENDPOINTS.school.sendReminderForFee, {
    params,
  });
};
export const updateInvitationCode = (code) => {
  return schoolTokenAxios.patch(
    `${API_ENDPOINTS.school.updateInvitationCode}/${code}`
  );
};
export const getAllSessions = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.school.getAllSessions);
};

export const editSchoolSession = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.editSchoolSession, payload);
};

export const addSchoolSession = (payload) => {
  const { schoolDocId, ...rest } = payload;
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.school.addSchoolSession}?schoolDocId=${schoolDocId}`,
    rest
  );
};
// export const verifySchoolCodeLogic = (code) => {
//   return authTokenAxios.get(`${API_ENDPOINTS.user.verifySchoolCode}/${code}`);
// };
// export const applyToSchoolLogic = (body) => {
//   return authTokenAxios.post(`${API_ENDPOINTS.user.applyToSchool}`, body);
// };
// export const getAllJoinedSchoolLogic = () => {
//   return authTokenAxios.get(`${API_ENDPOINTS.user.getAllJoinedSchool}`);
// };
// export const withdrawJoinedSchoolRequestLogic = (params) => {
//   return authTokenAxios.get(`${API_ENDPOINTS.user.withdrawJoiningRequest}`, {
//     params,
//   });
// };
export const getConstantSubject = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getConstantSubject}`, {
    params,
  });
};
export const getSectionList = () => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getSectionList}`);
};

export const getAllDepartments = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.school.getAllDepartments);
};

export const updateDepartment = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.updateDepartment, payload);
};

export const addDepartment = (payload) => {
  return schoolTokenAxios.post(API_ENDPOINTS.school.addDepartment, payload);
};

export const addUpdateDepartmentHead = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.school.addUpdateDepartmentHead,
    payload
  );
};

export const getAllClasses = (medium = "") => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.school.getAllClasses}${
      medium.length > 0 ? `?medium=${medium}` : ``
    }`
  );
};

export const deleteSection = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.deleteSection, payload);
};

export const addClass = (payload) => {
  const { medium } = payload;
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.school.addClass}?medium=${medium}`,
    payload
  );
};

export const addClassSection = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.addClassSection, payload);
};
export const copySubject = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.copySubject, payload);
};

export const getSubjectByClassDocIdSection = (
  classDocId = "",
  sectionName = ""
) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.school.getSubjectByClassDocIdSection}?classDocId=${classDocId}&section=${sectionName}`
  );
};

export const switchSubjectType = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.switchSubjectType, payload);
};

export const addSubject = (payload) => {
  return schoolTokenAxios.post(API_ENDPOINTS.school.addSubject, payload);
};
export const addStudentInSubject = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.school.addStudentInSubject,
    payload
  );
};
export const removeStudentFromSubject = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.school.removeStudentFromSubject,
    payload
  );
};

export const deleteSubjectByDocId = (subDocId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.school.deleteSubjectByDocId}/${subDocId}`
  );
};

export const deleteClass = (classDocId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.school.deleteClass}/${classDocId}`
  );
};
export const getStudentBySubjectDocId = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.school.getStudentBySubjectDocId}`,
    { params }
  );
};

export const updateClassTeacher = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.school.updateClassTeacher, payload);
};

export const updateSubjectTeacher = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.school.updateSubjectTeacher,
    payload
  );
};

export const updateSupportiveTeacherForSubject = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.school.updateSupportiveTeacherForSubject,
    payload
  );
};

export const pullPushSupportiveTeacherForSubject = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.school.pullPushSupportiveTeacherForSubject,
    payload
  );
};

export const getStaffByDepartmentDocId = (deptDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.school.getStaffByDepartmentDocId}?departmentDocId=${deptDocId}`
  );
};

export const getPermissions = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getPermissions}`, {
    params,
  });
};

export const addUpdateThoughtOfTheDay = (payload) => {
  return schoolTokenAxios.post(
    API_ENDPOINTS.school.addUpdateThoughtOfTheDay,
    payload
  );
};
export const addPermission = (payload) => {
  return schoolTokenAxios.post(API_ENDPOINTS.school.addPermission, payload);
};
export const updatePermission = (payload) => {
  return schoolTokenAxios.post(API_ENDPOINTS.school.updatePermission, payload);
};

//to get School Profile
export const getSchoolProfile = () => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getSchoolProfile}`);
};
export const getSchoolFirm = () => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getSchoolFirm}`);
};

export const getAllThought = () => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getAllThought}`);
};
export const getFoldersDetail = () => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.school.getFoldersDetail}`);
};
export const addFolder = (payload) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.school.addFolder}`, payload);
};
export const AddImage = (payload) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.school.AddImage}`, payload);
};
export const DeleteFolder = (subDocId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.school.DeleteFolder}?folderDocId=${subDocId}`
  );
};
export const getFoldersDetailImage = (subDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.school.getFoldersDetailImage}?folderDocId=${subDocId}`
  );
};

//update school
export const updateSchoolProfile = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.school.updateSchoolProfile}`,
    payload
  );
};

export const upsertSchoolFirmProfile = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.school.upsertSchoolFirmProfile}`,
    payload
  );
};

// export const addUpdateAboutSchool = (payload) => {
//   return schoolTokenAxios.post(
//     `${API_ENDPOINTS.school.addUpdateAboutSchool}`,
//     payload
//   );
// };
export const deleteThoughtOfDay = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.school.deleteThoughtOfDay}/${id}`
  );
};

//update Folder
export const updateFolderName = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.school.updateFolderName}`,
    payload
  );
};
export const EditImageDetail = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.school.EditImageDetail}`,
    payload
  );
};
export const DeleteImageDetail = (params) => {
  return schoolTokenAxios.delete(`${API_ENDPOINTS.school.DeleteImageDetail}`, {
    params,
  });
};
export const ApproveImageStatus = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.school.ApproveImageStatus}`,
    payload
  );
};

export const updatePic = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.school.updatePic}`, payload);
};
///add transportation Logbook
export const getAllTransportLog = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.transport.getAllTransportLog}`, {
    params,
  });
};
//post transport log
export const addTransportLog = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.transport.addTransportLog}`,
    payload
  );
};
//update Transport Log
export const updateTransportLogDetail = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.transport.updateTransportLogDetail}`,
    payload
  );
};
//delete Transport Log
export const deleteLogDetail = (transportId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.transport.deleteLogDetail}?transportLogDocId=${transportId}`
  );
};
export const deleteDepartment = (departmentId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.school.deleteDepartment}/${departmentId}`
  );
};
//to get all the records in the fee structure
export const getAllFeeStructure = (session, medium) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getAllFeeStructureNew}?session=${session}&medium=${medium}`
  );
};
export const getFeeStructureForStudentRegistration = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getFeeStructureForStudentRegistration}`,
    {
      params,
    }
  );
};

export const getAllFeeStructureNew = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getAllFeeStructureNew}`, {
    params: { ...params },
  });
};

export const runFeeByFeeStructure = (id) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.runFeeByFeeStructure}/${id}`
  );
};

//to get fee structure according to the classDocID

export const getFeeStructureByClassDocID = (feeCode, session, medium) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getAllFeeStructureNew}?feeCode=${feeCode}&session=${session}&medium=${medium}`
  );
};

//to get the advanced payment dropdown in the fee collection
export const getAdvancedPaymentDropDown = (
  session,
  medium,
  classDocId,
  section
) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getAllFeeStructureNew}?session=${session}&medium=${medium}&classDocId=${classDocId}&section=${section}&status=APPROVED`
  );
};

//to get the filter fee structure
// export const filterFeeStructureByClassDocId = (payload) => {
//   return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getAllFeeStructureNew}`, {
//     params: {
//       ...(payload.session && { session: payload.session }),
//       ...(payload.status && { status: payload.status }),
//       ...(payload.medium && { medium: payload.medium }),
//       ...(payload.classDocId &&
//         payload.classDocId !== "" && { classDocId: payload.classDocId }),
//       ...(payload.section &&
//         payload.section !== "" && { section: payload.section }),
//       ...(payload.feeType && { feeType: payload.feeType }),
//     },
//   });
// };

export const filterFeeStructureByClassDocId = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getAllFeeStructureNew}`, {
    params,
  });
};

//to get all the class dropdown
export const getAllClassDropdowns = (schoolDocId, session, medium) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getAllClassDropdown}/${schoolDocId}${
      session && medium ? `?session=${session}&medium=${medium}` : ""
    }`
  );
};

export const addNewFeeStructure = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.fees.addFeeStructure}`,
    payload
  );
};
export const getOldInvoice = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getOldInvoice}`, {
    params,
  });
};
export const getDistinctFeeCollection = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getDistinctFeeCollection}`,
    {
      params,
    }
  );
};
export const getFeeCollection = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getFeeCollection}`, {
    params,
  });
};
export const convertOldInvoiceToNewInvoice = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.convertOldInvoiceToNewInvoice}`,
    body
  );
};

export const approveFeeStructureRequest = (feeStructureDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getApproveFeeStructure}?feeStructureDocId=${feeStructureDocId}`
  );
};

export const approveFeeStructureNew = (feeStructureDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getApproveFeeStructure}/${feeStructureDocId}`
  );
};

export const rejectFeeStructureRequest = (feeStructureDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getRejectFeeStructure}?feeStructureDocId=${feeStructureDocId}`
  );
};

export const rejectFeeStructureNew = (feeStructureDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getRejectFeeStructure}/${feeStructureDocId}`
  );
};

export const getAllDuesTillDate = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getDuesTillDate}`, {
    params: {
      ...params,
    },
  });
};

//hardcoded values for sessionFrom
export const getFeeCollectionByStudent = (studentDocId, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getFeeCollectionByStudent}`,
    {
      params: {
        studentDocId: studentDocId,
        session: session,
      },
    }
  );
};

export const payFee = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.fees.payFee}`, payload);
};

export const getFeeStructureByFeeDocId = (feeDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getAllFeeStructureNew}?feeStructureDocId=${feeDocId}`
  );
};

export const updateFeeStructure = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.updateFeeStructure}`,
    payload
  );
};
// export const deleteFeeStructure = (payload) => {
//   return schoolTokenAxios.put(
//     `${API_ENDPOINTS.fees.deleteFeeStructure}`,
//     payload
//   );
// };
export const updateFeeStructureRelaxationCategory = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.updateFeeStructureRelaxationCategory}`,
    payload
  );
};
export const chnageFeeTypeNameInFeeCollection = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.chnageFeeTypeNameInFeeCollection}`,
    payload
  );
};

export const getRolesForSchool = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.auth.getRolesForSchool);
};
export const changeRole = (payload) => {
  return schoolTokenAxios.put(API_ENDPOINTS.auth.changeRole, payload);
};

export const getStudents1 = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const addFeeStructureInExistingGroup = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.fees.addFeeStructureInExistingGroup,
    payload
  );
};
export const getFeeCollectionsReportByUser = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getFeeCollectionsReportByUser}`,
    {
      params,
    }
  );
};
export const getFeeRelaxationCategory = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getFeeRelaxationCategory}`,
    { params }
  );
};
export const addFeeRelaxationCategory = (body) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.fees.addFeeRelaxationCategory}`,
    body
  );
};
export const deleteFeeRelaxationCategory = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.fees.deleteFeeRelaxationCategory}/${id}`
  );
};
export const getFeeType = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getFeeType}`, { params });
};
export const getFeeTypeForMultipleClass = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.getFeeTypeForMultipleClass}`,
    { params }
  );
};
export const addFeeType = (body) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.fees.addFeeType}`, body);
};
export const deleteFeeType = (body) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.fees.deleteFeeType}`, body);
};
export const addFeeStructureGroup = (body) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.fees.addFeeStructureGroup}`,
    body
  );
};
export const updateFineRate = (body) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.fees.updateFineRate}`, body);
};
export const addFeeStructureInGroup = (body) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.fees.addFeeStructureInGroup}`,
    body
  );
};
export const updateFeeStructureInGroup = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.updateFeeStructureInGroup}`,
    body
  );
};
export const deleteFeeStructure2 = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.fees.deleteFeeStructure2}/${id}`
  );
};
export const approveFeeStructure = (id) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.approveFeeStructure}/${id}`
  );
};
export const rejectFeeStructure = (id) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.rejectFeeStructure}/${id}`);
};

export const getFeeStructureGroup = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getFeeStructureGroup}`, {
    params,
  });
};
export const linkFeeStructureWithGroup = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.fees.linkFeeStructureWithGroup}`,
    body
  );
};
export const getAllFeeStructureNew2 = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getAllFeeStructureNew}`, {
    params,
  });
};
export const deleteFeeStructureGroup = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.fees.deleteFeeStructureGroup}/${id}`
  );
};
