import { getAllBusinessCategory } from "app/services/schoolService/firm.service";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import TextFieldLabel from "../common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

const ByBusinessCategory = ({
  prevBusinessCategoryList,
  setSelectedBusinessCategory,
  label = false,
}) => {
  const [state, setState] = useState({
    businessCategory: [],
    businessCategoryList: [],
  });

  const {
    isLoading: getAllBusinessCategoryLoading,
    isFetching: getAllBusinessCategoryFetching,
    refetch: getAllBusinessCategoryRefetch,
  } = useQuery({
    queryKey: ["getAllBusinessCategory"],
    queryFn: () => getAllBusinessCategory(),
    onSuccess: (res) => {
      const categoryList = res?.data?.list.map((category) => ({
        businessCategoryName: category,
        checked: false,
      }));

      if (prevBusinessCategoryList.length > 0) {
        let updatedbusinessCategoryList = categoryList.map((item) => ({
          ...item,
          checked: prevBusinessCategoryList.some(
            (prevItem) =>
              prevItem.businessCategoryName === item.businessCategoryName &&
              prevItem?.checked === true
          ),
        }));
        setState((prev) => ({
          ...prev,
          businessCategoryList: updatedbusinessCategoryList,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          businessCategoryList: categoryList,
        }));
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  useEffect(() => {
    if (state.businessCategoryList.length > 0) {
      setSelectedBusinessCategory(state.businessCategoryList);
    }
  }, [state.businessCategoryList]);

  return (
    <>
      {label && <TextFieldLabel title={"Select Business Category"} />}
      <CustomSelectBox
        MenuProps={MenuProps}
        multiple
        displayEmpty
        size="small"
        fullWidth
        value={state.businessCategory}
        renderValue={() => {
          const selectedItems = state.businessCategoryList?.filter(
            (item) => item?.checked
          );
          if (selectedItems.length > 0) {
            return (
              <Typography sx={{ fontWeight: 500, pr: 1 }}>
                {selectedItems
                  .map((item) => item.businessCategoryName)
                  .join(", ")}
              </Typography>
            );
          } else {
            return <Typography>Select</Typography>;
          }
        }}
      >
        {state.businessCategoryList?.map((item, i) => (
          <Box key={i} sx={{ px: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={item?.checked}
                  onChange={() => {
                    item.checked = !item.checked;
                    setState((prev) => ({ ...prev }));
                  }}
                />
              }
              label={item.businessCategoryName}
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
          </Box>
        ))}
      </CustomSelectBox>
    </>
  );
};

export default ByBusinessCategory;
