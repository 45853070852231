import {
  Autocomplete,
  Box,
  Button,
  Container,
  MenuItem,
  Radio,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import {
  selectActiveSession,
  selectedMediumSelector,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import { getStudentForPromotionOrMigrationLogic } from "app/services/student-management";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import MigrateModal from "../../components/PromotionAndMigrationComponents/MigrateModal";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { toast } from "react-toastify";
import appRoutes from "app/config/routes";
import { MenuProps } from "app/utils/helper";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { LoadingButton } from "@mui/lab";
import { getClassDropdownLogic } from "app/services/reception";
import CustomLoader from "app/components/common/CustomLoader";
import TableProfilePic from "app/components/common/TableProfilePic";
import StudentSearchDialog from "app/components/common/Dialogs/StudentSearchDialog";
import StudentSearchInput from "app/components/common/StudentSearchInput";
import ExportDialog from "app/components/common/exportDialog";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { YouTube } from "@mui/icons-material";
import YoutubeVideoDialog from "app/components/common/Dialogs/YoutubeVideoDialog";
import ByClassFilter from "app/components/commonSeperateMultipleFilter/ByClassFilter";

export default function PromotionAndMigration() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const session = useSelector(selectActiveSession);
  const medium = useSelector(selectedMediumSelector);
  const heightRef = useRef(null);
  const [classOptions, setClassOptions] = useState([]);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const [mainData, setMainData] = useState([]);
  const [isMigrateModalVisible, setIsMigrateModalVisible] = useState(false);
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classListToMutate, setClassListToMutate] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const [state, setState] = useState({
    nameSearchDialog: false,
    classDocId: "",
    radio: "By Class",
    className: "",
    section: "",
    sectionList: [],
    admissionNumber: "",
    studenttName: "",
    registrationNumber: "",
    mobilNumber: "",
  });
  let header = [
    "#",
    "Registration Number",
    "Student Name",
    "Class Name",
    "Admission Number",
    "Gender",
    "Mobile Number",
    "Father's Name",
    "Address",
    "Total Dues",
  ];
  const navigate = useNavigate();
  const handleSubmitNameSearch = (list) => {
    getStudentsListLogicMutate({
      reqStudentDocIdList: JSON.stringify(list),
      session: session,
    });
  };
  const {
    mutate: getStudentsListLogicMutate,
    isLoading: getStudentsListLogicMutateLoading,
  } = useMutation(getStudentForPromotionOrMigrationLogic, {
    onSuccess: (data) => {
      setMainData(data?.data?.list);
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    isLoading: studentListLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["getStudents1", session],
    queryFn: () => {
      let body = {};
      switch (state.radio) {
        case "By Class":
          body = {
            ...(state.classDocId && { classDocId: state.classDocId }),
            ...(state.section && { section: state.section }),
            ...(classListToMutate.length > 0 && {
              classList: JSON.stringify(classListToMutate),
            }),
          };
          break;
        case "Admission Number":
          body = {
            admissionNumber: state.admissionNumber,
          };
          break;

        case "Registration Number":
          body = {
            registrationNumber: state.registrationNumber,
          };
          break;
        case "Mobile Number":
          body = {
            mobileNumber: state.mobilNumber,
          };
          break;

        default:
          break;
      }
      return getStudentForPromotionOrMigrationLogic({ ...body, session });
    },
    onSuccess: (data) => {
      setMainData(data?.data?.list);
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: false,
  });

  useEffect(() => {
    const filteredList = selectedClasses?.map(({ classDocId, section }) => ({
      classDocId,
      section,
    }));
    setClassListToMutate(filteredList);
  }, [selectedClasses]);

  const { isLoading: classLoading, isFetching: classFetching } = useQuery({
    queryKey: ["getClassInfo", session, medium],
    queryFn: () =>
      getClassDropdownLogic({
        schoolDocId: schoolDocId,
        selectedSession: session,
        selectedMedium: medium,
      }),
    onSuccess: ({ data }) => {
      setClassOptions(data?.list);
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  const handleViewAction = (cell) => {
    const {
      row: { original },
    } = cell;

    navigate(
      `/${appRoutes.edit_promotion_student_details}/${original?.studentDocId}`
    );
  };

  const handleMigrateAction = (cell) => {
    const {
      row: { original },
    } = cell;
    navigate(`/${appRoutes.migrate}/${original?.studentDocId}`);
  };

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <p
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {parseInt(cell.row.id) + 1}
          </p>
        ),
        maxSize: 30,
      },
      {
        header: "Reg No.",
        accessorKey: "registrationNumber",
        maxSize: 60,
      },
      {
        header: "Identity",
        accessorKey: "admissionNumber",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box>
              <div title="SR Number">
                <Typography>{original?.srNumber ?? "--"}</Typography>
              </div>
              <div title="Admission Number">
                <Typography sx={{ color: "#F0C62E" }}>
                  {original?.admissionNumber ?? "--"}
                </Typography>
              </div>
              <div title="Reg. Number">
                <Typography sx={{ color: "#2789FD" }}>
                  {original?.registrationNumber ?? "--"}
                </Typography>
              </div>
            </Box>
          );
        },
        maxSize: 60,
      },
      {
        header: "Student Name",
        accessorKey: "studentName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Link
              to={`/${appRoutes.student_profile}/${original?.studentDocId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stack direction="row" alignItems="center" gap={0.6}>
                <TableProfilePic image={original?.profilePic} />
                <Typography>{original?.studentName}</Typography>
              </Stack>
            </Link>
          );
        },
        size: 80,
      },

      {
        header: "Gender",
        accessorFn: (row) => row?.gender,
        maxSize: 60,
      },

      {
        header: "Father's Name",
        accessorFn: (row) => `${row?.fatherName}`,
        maxSize: 80,
      },

      {
        header: "Total Dues",
        accessorKey: "totalDues",
        Cell: ({ cell }) => {
          return (
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography>
                {cell?.row?.original?.totalDues ?? 0} +{" "}
                {cell?.row?.original?.otherSoftwareArrear?.arrearDetail
                  ?.t_balanceAmount ?? 0}
              </Typography>
            </Stack>
          );
        },
        maxSize: 60,
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <Stack direction="row" alignItems="center" gap={1}>
              <Button
                onClick={() => {
                  handleViewAction(cell);
                }}
                variant="contained"
                color="primary"
                size="small"
              >
                Promote
              </Button>

              <Button
                onClick={() => {
                  handleMigrateAction(cell);
                }}
                variant="contained"
                color="success"
                size="small"
              >
                Migrate
              </Button>
            </Stack>
          );
        },
        size: isMobile ? 140 : 60,
      },
    ];
  }, []);

  let exportArray = mainData?.map((item, index) => {
    return {
      "#": index < 9 ? `0${index + 1}` : index + 1,
      "Registration Number": item?.registrationNumber ?? "--",
      "Student Name": item?.studentName ?? "--",
      "Class Name": item?.className_stream ?? "--",
      "Admission Number": item?.admissionNumber ?? "--",
      Gender: item?.gender ?? "--",
      "Mobile Number": item?.mobileNumber ?? "--",
      "Father's Name": item?.fatherName ?? "--",
      Address: `${item?.currentAddress?.address}, ${item?.currentAddress?.dist}, ${item?.currentAddress?.pinCode}, ${item?.currentAddress?.state}`,
      "Total Dues":
        (item?.totalDues ?? 0) +
        (item?.otherSoftwareArrear?.arrearDetail?.t_balanceAmount
          ? item?.otherSoftwareArrear?.arrearDetail?.t_balanceAmount
          : 0),
    };
  });

  const videoLink = [
    {
      link: "https://www.youtube.com/embed/EPuRLA_aEp8?si=YawhvZKBQuwhj8vl",
      title: "How to Promote Student",
    },
    {
      link: "https://www.youtube.com/embed/eJ1QqpAcePU?si=P_sz1jmwJcg-nque",
      title: "How to Migrate Student",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Promotion and Migration</title>
      </Helmet>
      <CustomLoader
        show={
          classLoading || classFetching || getStudentsListLogicMutateLoading
        }
      />

      <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      />

      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Promotion And Migration"
      />
      <MigrateModal
        open={isMigrateModalVisible}
        setOpen={setIsMigrateModalVisible}
      />
      <Container
        maxWidth="xl"
        disableGutters={isMobile ? true : false}
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Typography
          sx={{ fontSize: isMobile ? "14px" : "16px", fontWeight: 500 }}
          color="primary.main"
        >
          Filter By:-
        </Typography>
        <Stack direction="row" alignItems={!isMobile && "center"} gap={1}>
          {[
            "By Class",
            "Admission Number",
            "By Name",
            "Registration Number",
            "Mobile Number",
          ].map((item, ind) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems="center"
              key={ind}
              flex={isMobile && 1}
            >
              <Radio
                color="secondary"
                value={item}
                onChange={() => setState((prev) => ({ ...prev, radio: item }))}
                checked={state.radio === item ? true : false}
              />
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontSize: isMobile ? "12px" : "14px",
                  fontWeight: 500,
                }}
              >
                {item}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction="row" alignItems="flex-end" gap={2} sx={{ pb: 2 }}>
            {state.radio === "By Name" && (
              <StudentSearchInput
                loading={getStudentsListLogicMutateLoading}
                handleSearch={handleSubmitNameSearch}
              />
            )}
            {state.radio === "By Class" && (
              <>
                <Box
                  sx={{ width: isMobile ? "40vw" : "15vw" }}
                  mt={isMobile && 2}
                >
                  <ByClassFilter
                    prevClassList={selectedClasses}
                    setSelectedClasses={setSelectedClasses}
                    // onClassListChange={handleClassListChange}
                  />
                  {/* <CustomSelectBox
                    MenuProps={MenuProps}
                    fullWidth
                    value={state.classDocId}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>

                    {classOptions &&
                      classOptions?.map((opt, i) => {
                        return (
                          <MenuItem
                            key={opt?.classDocId}
                            value={opt?.classDocId}
                            name={opt?.className_stream}
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                classDocId: opt?.classDocId,
                                section: "",
                                sectionList: opt?.sections,
                              }))
                            }
                          >
                            {opt?.className_stream}
                          </MenuItem>
                        );
                      })}
                  </CustomSelectBox> */}
                </Box>
                {/* {state.classDocId !== "all" && (
                  <Box sx={{ width: isMobile ? "40vw" : "10vw" }}>
                    <TextFieldLabel
                      styleProps={{ fontSize: isMobile && "12px" }}
                      title="Section"
                    />
                    <CustomSelectBox
                      displayEmpty
                      fullWidth
                      value={state.section}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          section: e.target.value,
                        }))
                      }
                      disabled={state.classDocId ? false : true}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      {state.sectionList &&
                        state.sectionList?.map((opt, i) => {
                          return (
                            <MenuItem key={i} value={opt}>
                              {opt}
                            </MenuItem>
                          );
                        })}
                    </CustomSelectBox>
                  </Box>
                )} */}
              </>
            )}
            {state.radio === "Admission Number" && (
              <Box
                sx={{ width: isMobile ? "90vw" : "15vw" }}
                mt={isMobile && 2}
              >
                <TextFieldLabel
                  styleProps={{ fontSize: isMobile && "12px" }}
                  title="Admission Number"
                />
                <CustomTextField
                  fullWidth
                  placeholder="Type Admission Number"
                  value={state.admissionNumber}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      admissionNumber: e.target.value,
                    }))
                  }
                />
              </Box>
            )}

            {state.radio === "Registration Number" && (
              <Box
                sx={{ width: isMobile ? "90vw" : "15vw" }}
                mt={isMobile && 2}
              >
                <TextFieldLabel
                  styleProps={{ fontSize: isMobile && "12px" }}
                  title="Registration Number"
                />
                <CustomTextField
                  fullWidth
                  placeholder="Type Registration Number"
                  value={state.registrationNumber}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      registrationNumber: e.target.value,
                    }))
                  }
                />
              </Box>
            )}
            {state.radio === "Mobile Number" && (
              <Box
                sx={{ width: isMobile ? "90vw" : "15vw" }}
                mt={isMobile && 2}
              >
                <TextFieldLabel
                  styleProps={{ fontSize: isMobile && "12px" }}
                  title="Mobile Number"
                />
                <CustomTextField
                  fullWidth
                  placeholder="Type Mobile Number"
                  value={state.mobilNumber}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      mobilNumber: e.target.value,
                    }))
                  }
                />
              </Box>
            )}
            {state.radio !== "By Name" && (
              <LoadingButton
                loading={studentListLoading || isFetching}
                color="secondary"
                variant="contained"
                size="large"
                onClick={() => refetch()}
                sx={{ mb: 0.5, textTransform: "capitalize" }}
              >
                Search
              </LoadingButton>
            )}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                mb: 1.5,
              }}
            >
              Total Student :{" "}
              <span style={{ fontSize: "18px" }}>{mainData?.length || 0}</span>
            </Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <ExportButtonGroup
              handleClickExcel={() => setOpenCheckBox(true)}
              showPdf={false}
            />
            <YouTube
              sx={{
                color: "#FF0000",
                fontSize: "2.5rem",
                cursor: "pointer",
                mt: 2,
              }}
              onClick={() => setOpenYoutubeDialog(true)}
            />
          </Stack>
        </Stack>

        <Box sx={{ height: "100%" }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={mainData}
            maxHeight={`${heightRef?.current?.clientHeight - 70}px`}
          />
        </Box>
      </Container>
    </>
  );
}
