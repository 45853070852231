import { ArrowForwardIos } from "@mui/icons-material";
import {
  Divider,
  Collapse,
  Stack,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const CollectionHeadDropdown = ({
  item,
  formikState,
  index,
  formikSetState,
}) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(item);
  const isMounted = useRef(false);
  // console.log(formikState, "formikState");

  const handleCheckHead = () => {
    const updatedData = { ...data };
    if (updatedData.checked) {
      updatedData.checked = false;
      updatedData.subHeadList.forEach((head) => {
        head.checked = false;
      });
    } else {
      updatedData.subHeadList.forEach((head) => {
        head.checked = true;
      });
      updatedData.checked = true;
    }
    setData(updatedData);
  };

  const handleCheckSubHead = (subHeadId) => {
    const updatedData = { ...data };
    let allHeadCheck = true;
    updatedData.subHeadList = updatedData.subHeadList.map((subHead) => {
      if (subHead.collectionSubHeadDocId === subHeadId) {
        subHead.checked = !subHead.checked;
      }
      if (!subHead.checked) {
        allHeadCheck = false;
      }
      return subHead;
    });
    updatedData.checked = allHeadCheck;
    setData(updatedData);
  };

  useEffect(() => {
    // console.log("filteredByHeadId");

    // if (isMounted.current) {
    const filteredByHeadId = formikState?.postHeadList?.filter(
      (res) => res?.collectionHeadDocId !== data?.collectionHeadDocId
    );
    console.log("formikState?.postHeadList", formikState?.postHeadList);
    console.log("data", data);
    const newArray = [];
    data?.subHeadList?.forEach((res) => {
      if (res?.checked) {
        newArray.push({
          collectionHeadDocId: data?.collectionHeadDocId,
          collectionHead: data?.collectionHead,
          collectionHeadName: data?.collectionHeadName,
          collectionSubHeadName: res?.collectionSubHeadName,
          collectionSubHeadDocId: res?.collectionSubHeadDocId,
          checked: true,
          display: `${data?.collectionHeadName}(${res?.collectionSubHeadName})`,
        });
      }
    });

    // console.log("data?.subHeadList", data?.subHeadList);
    // console.log("newArray", newArray);

    formikSetState((prev) => ({
      ...prev,
      postHeadList: [...filteredByHeadId, ...newArray],
    }));
    // } else {
    //   isMounted.current = true;
    // }
  }, [data]);

  return (
    <>
      <Box key={data?.collectionHeadDocId}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ px: 1, py: 0.5, width: "100%" }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Checkbox
              color="secondary"
              checked={data?.checked}
              onChange={handleCheckHead}
            />
            <Typography>{data?.collectionHeadName}</Typography>
            <Typography
              sx={{
                color: "blue",
                fontWeight: 600,
                fontSize: "14px",
                mt: "-1px",
              }}
            >
              {data?.subHeadList
                ?.filter((item) => item.checked)
                .map((item) => item?.collectionSubHeadName)
                .join(", ")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            onClick={() => setExpand(!expand)}
            sx={{ cursor: "pointer", flexGrow: 1 }}
          >
            <ArrowForwardIos
              sx={{
                fontSize: "14px",
                transform: expand
                  ? "rotate(270deg) !important"
                  : "rotate(0deg) !important",
                cursor: "pointer",
              }}
            />
          </Stack>
        </Stack>
        <Divider sx={{ background: "#B6C8D6" }} />
        <Collapse in={expand}>
          <Stack direction="row" gap={1} sx={{ pl: 5 }} flexWrap={"wrap"}>
            {data?.subHeadList?.map((subHead) => (
              <Stack
                direction="row"
                alignItems="center"
                key={subHead?.collectionSubHeadDocId}
              >
                <Checkbox
                  color="secondary"
                  checked={subHead?.checked}
                  onChange={() =>
                    handleCheckSubHead(subHead.collectionSubHeadDocId)
                  }
                />
                <Typography>{subHead?.collectionSubHeadName}</Typography>
              </Stack>
            ))}
          </Stack>
          <Divider sx={{ background: "#B6C8D6" }} />
        </Collapse>
      </Box>
    </>
  );
};

export default CollectionHeadDropdown;
