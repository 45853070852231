import React, { useEffect, useState } from "react";
import FilterButton from "../../FilterButton";
import CommonFilterDialog from "../CommonFilterDialog";
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import TextFieldLabel from "../../TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { getFeeType } from "app/services/schoolService/newFee.service";
import ByClassFilter from "app/components/commonSeperateMultipleFilter/ByClassFilter";
import ByFeeFilter from "app/components/commonSeperateMultipleFilter/ByFeeFilter";
const IndivisualFeeSturctureFilter = ({
  classList = [],
  mutate = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [selectedFees, setSelectedFees] = useState([]);

  const [state, setState] = useState({
    dialog: false,
    feeType: "",
    feeTypeList: [],
    classDocId: "",
    section: "",
    status: "",
    sectionList: [],
  });
  const [checkboxes, setCheckboxes] = useState({
    feeType: false,
    class: false,
    status: false,
  });
  const handleCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classListToMutate, setClassListToMutate] = useState([]);

  const handleView = () => {
    const body = {
      // ...(checkboxes.feeType && { feeType: state.feeType }),
      ...(checkboxes.feeType &&
        selectedFees?.filter((item) => item?.checked)?.length > 0 && {
          reqFeeTypeList: JSON.stringify(
            selectedFees
              ?.filter((item) => item?.checked)
              ?.map((item) => item?.feeType)
          ),
        }),
      ...(checkboxes.status && { status: state.status }),
      ...(checkboxes.class && {
        // ...(state.classDocId && { classDocId: state.classDocId }),
        // ...(state.section && { section: state.section }),
        ...(classListToMutate.length > 0 && {
          classList: JSON.stringify(classListToMutate),
        }),
      }),
    };

    mutate(body);
    setState((prev) => ({ ...prev, dialog: false }));
  };

  const { isLoading: getFeeTypeLoading, isFetching: getFeeTypeFetching } =
    useQuery({
      queryKey: ["getFeeType"],
      queryFn: () =>
        getFeeType({ isGroupedByClass: "Yes", session: selectedSession }),
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          feeTypeList: [
            { feeType: "Transportation Fee" },
            ...success?.data?.list,
          ],
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: checkboxes.feeType ? true : false,
    });
  useEffect(() => {
    if (state.classDocId) {
      const filteredData = classList?.find(
        (item) => item?.classDocId === state.classDocId
      );
      setState((prev) => ({
        ...prev,
        sectionList: filteredData?.sections,
        section: "",
      }));
    }
  }, [state.classDocId]);

  useEffect(() => {
    const filteredList = selectedClasses?.map(({ classDocId, section }) => ({
      classDocId,
      section,
    }));
    setClassListToMutate(filteredList);
  }, [selectedClasses]);

  return (
    <>
      <FilterButton
        hanldeClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((item) => item === true).length}
      />
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseFilterDialog}
        handleSaveAndView={handleView}
        minWidth="500px"
      >
        <DialogContent>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.feeType}
                  onChange={(e) => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      feeType: !prev.feeType,
                    }));
                  }}
                />
              }
              label="By Fee Type"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            {checkboxes.feeType && (
              <ByFeeFilter
                showTransportFee={true}
                prevFeeList={selectedFees}
                setSelectedFees={setSelectedFees}
              />
              // <Stack flex={1}>
              //   <TextFieldLabel title="Select Fee Type" />
              //   <CustomSelectBox
              //     native
              //     MenuProps={MenuProps}
              //     displayEmpty
              //     size="small"
              //     fullWidth
              //     value={state.feeType}
              //     onChange={(e) =>
              //       setState((prev) => ({ ...prev, feeType: e.target.value }))
              //     }
              //   >
              //     <MenuItem value="" disabled component="option">
              //       Select
              //     </MenuItem>
              //     {state.feeTypeList?.map((item, i) => (
              //       <MenuItem value={item?.feeType} key={i} component="option">
              //         {item?.feeType}
              //       </MenuItem>
              //     ))}
              //   </CustomSelectBox>
              // </Stack>
            )}
          </Stack>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.class}
                  onChange={(e) => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      class: !prev.class,
                    }));
                  }}
                />
              }
              label="Class-Stream-Section"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            {checkboxes.class && (
              <Stack direction="row">
                {/* <Stack flex={1}> */}
                {/* <Box sx={{ width: { xs: "60vw", md: "25vw" } }}> */}
                <ByClassFilter
                  prevClassList={selectedClasses}
                  setSelectedClasses={setSelectedClasses}
                  // onClassListChange={handleClassListChange}
                />
                {/* </Box> */}
                {/* <TextFieldLabel title="Class-Stream" /> */}
                {/* <CustomSelectBox
                    native
                    size="small"
                    select
                    MenuProps={MenuProps}
                    displayEmpty
                    value={state.classDocId}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        classDocId: e.target.value,
                        className: "",
                        section: "",
                      }))
                    }
                    fullWidth
                  >
                    <MenuItem value="" component="option">
                      Select
                    </MenuItem>

                    {classList?.map((opt, i) => {
                      return (
                        <MenuItem
                          component="option"
                          key={opt?.classDocId}
                          value={opt?.classDocId}
                        >
                          {opt?.className_stream}
                        </MenuItem>
                      );
                    })}
                  </CustomSelectBox> */}
                {/* </Stack> */}

                {/* <Stack flex={1}>
                  <TextFieldLabel title="Section" />
                  <CustomSelectBox
                    native
                    MenuProps={MenuProps}
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state?.section}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        section: e.target.value,
                      }))
                    }
                    disabled={!state?.classDocId}
                  >
                    <MenuItem value="" component="option">
                      Select
                    </MenuItem>

                    {state?.sectionList &&
                      state?.sectionList.map((opt, i) => {
                        return (
                          <MenuItem key={i} value={opt} component="option">
                            {opt}
                          </MenuItem>
                        );
                      })}
                  </CustomSelectBox>
                </Stack> */}
              </Stack>
            )}
          </Stack>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.status}
                  onChange={(e) => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      status: !prev.status,
                    }));
                  }}
                />
              }
              label="Status"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            {checkboxes.status && (
              <Stack flex={1}>
                <TextFieldLabel title="Select Status" />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  displayEmpty
                  size="small"
                  fullWidth
                  value={state?.status}
                  onChange={(e) =>
                    setState((prev) => ({ ...prev, status: e.target.value }))
                  }
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="APPROVED">APPROVED</MenuItem>
                  <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                  <MenuItem value="REJECTED">REJECTED</MenuItem>
                </CustomSelectBox>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default IndivisualFeeSturctureFilter;
