import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios, authTokenAxios } from "../axios";

//HOMEWORK PAGE
export const getHomeWorkLogic = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.academic.getHomework}`, {
    params,
  });
};

export const getSummarizedHomework = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.academic.getSummarizedHomework}`,
    {
      params,
    }
  );
};

export const getSummarizedHomeworkForExport = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.academic.getSummarizedHomeworkForExport}`,
    {
      params,
    }
  );
};

export const getAllSubjectLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getAllSubject}?classDocId=${payload.classId}&section=${payload.oneSection}`
  );
};
export const getSubjectsForHomework = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.academic.getSubjectsForHomework}?classDocId=${payload.classId}&section=${payload.oneSection}`
  );
};

export const getStudentLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        classDocId: payload.streamValue,
        section: payload.section,
        session: payload.session,
      },
    }
  );
};
export const getStudentsByNameLogic = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudentsByName}`,
    {
      params,
    }
  );
};

export const addHomeworkLogic = (body) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.academic.addHomework}`, body);
};
export const editHomeworkLogic = (body) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.academic.editHomework}`, body);
};
export const deleteHomework = (params) => {
  return schoolTokenAxios.delete(`${API_ENDPOINTS.academic.deleteHomework}`, {
    params: params,
  });
};

//Attendance and leave

export const getStudentAttendanceByDateLogic = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.academic.getStudentAttendanceByClassAndDate}`,
    {
      params: {
        ...params,
      },
    }
  );
};
export const getStudentAttendance = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.academic.getStudentAttendance}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const createAttendanceByClassLogic = (params) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.academic.createAttendanceByClass}`,
    {
      ...params,
    }
  );
};

export const editStudentAttendanceLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.academic.editStudentAttendance}?session=${payload.selectedSession}&medium=${payload.selectedMedium}`,
    { listOfAttendance: payload.data }
  );
};
export const markAsHolidayClassLogic = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.academic.markAsHolidayClass}`,
    body
  );
};

// time Table
export const getAllTimeTableLogic = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.academic.getTimeTable}`, {
    params,
  });
};
export const getTimeTableByTimetableIdLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.academic.getTimeTable}?timetableDocId=${payload.timetableDocId}`
  );
};
export const addTimeTableLogic = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.academic.addTimeTable}`,
    payload
  );
};
export const editTimeTableLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.academic.editTimeTable}`,
    payload
  );
};
export const rejectTimeTableLogic = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.academic.rejectTimeTable}`, {
    params,
  });
};
export const approveTimeTableLogic = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.academic.approveTimeTable}`, {
    params,
  });
};
export const deleteTimeTableLogic = (timetableDocId) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.academic.deleteTimeTable}/${timetableDocId}`
  );
};
export const activateTimeTableLogic = (timetableDocId) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.academic.activateTableById}/${timetableDocId}`
  );
};
